import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { saveAs } from "file-saver";

import {
  Collapse,
  IconButton,
  TableCell,
  TableRow
} from "@material-ui/core";

import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Brightness1,
  Check,
} from "@material-ui/icons";

import {
  makeReload,
  makeReboot,
  makeScreenshot,
  getScreenshot,
} from "../../services/oubabox.services";

import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";
import Boxes from "./Boxes";

const useRowStyles = makeStyles({
  ...styles(),
  root: {
    "& > *": {
      borderBottom: "unset",
    }
  }
});

export const AddBox = ({ toggleModal, oubaboxId }) => {
  const classes = useRowStyles();
  return (
    <Button
      className={classes.button}
      startIcon={<AddIcon />}
      onClick={() => toggleModal(null, oubaboxId)}
      style={{
        marginTop: "5px",
        boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      }}
    >
      Ajouter un casier
    </Button>
  );
};

const Oubabox = ({
  data,
  sizesList,
  toggleModal,
  toggleDisplayLogs,
  toggleConfirmDestroy,
  handleUpdateBoxSize,
  getOubaboxStatusColor
}) => {
  const [open, setOpen] = useState(false);
  const myself = JSON.parse(localStorage.getItem("myself"));
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          <Brightness1 style={{
            color: getOubaboxStatusColor('device', data)
          }} />
        </TableCell>
        <TableCell align="center">
          {data.label}
        </TableCell>
        <TableCell align="center">
          {data.estate?.name}
        </TableCell>
        <TableCell align="center">
          {["Déconnecté", "Disponible", "En Usage", "En Maintenance"]
          [data?.state] ?? "Iconnu"}
        </TableCell>
        <TableCell align="right">
          {data.stateUpdatedAt
            ? moment(data.stateUpdatedAt).format("DD MMMM YYYY [à] HH[h]mm")
            : "Jamais"}
        </TableCell>
        <TableCell align="center">
          <div style={{
            alignItems: "self-end",
            justifyContent: "flex-end"
          }}>
            {data.incidentNumber ? (
              <Button
                size="sm"
                color="info"
                variant="outlined"
                onClick={() => toggleDisplayLogs(data.uuid, data.logs)}
              >
                {data.incidentNumber} incident{data.incidentNumber >= 2 && 's'}
              </Button>
            ) : (
              <>
                <Check />
                Aucun
              </>
            )}
          </div>
        </TableCell>
        {(myself?.rights?.role_superadmin ||
        myself?.rights?.role_oubabox_maintainer || myself?.rights?.role_oubabox_provider) && (
          <TableCell align="center">
            <Button
              size="sm"
              onClick={() => toggleDisplayLogs(data.uuid)}
              color="primary"
            >
              Logs
            </Button>
            <Button
              size="sm"
              color="primary"
              onClick={async () => saveAs(
                (await makeScreenshot(data.uuid)),
                `${data.label}-${new Date().toLocaleDateString("fr-FR")}-${new Date().toLocaleTimeString("fr-FR")}.png`
            )}>
              Screenshot
            </Button>
            <Button
              size="sm"
              onClick={() => makeReload(data.uuid)}
              color="primary"
              >
              Actualiser
            </Button>
            <Button
              size="sm"
              onClick={() => makeReboot(data.uuid)}
              color="primary"
              >
              Redémarer
            </Button>
          </TableCell>
        )}
        {(myself?.rights?.role_superadmin ||
          myself.rights?.role_oubabox_provider) && (
          <TableCell align="center">
            <Button
              size="sm"
              color="warning"
              onClick={() => toggleModal(data)
            }>
              Éditer
            </Button>
            <Button
              size="sm"
              color="danger"
              onClick={() => toggleConfirmDestroy(data)
            }>
              Supprimer
            </Button>
          </TableCell>
        )}
      </TableRow>
      <TableRow style={{ width: "100%" }}>
        <TableCell colSpan={9} style={{
          padding: 0
        }}>
          <Collapse in={open} component='div' timeout="auto" unmountOnExit>
            {!data?.boxes?.length ? (
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}>
                <div>
                  Aucune boites rattaché à cette Oubabox &nbsp; &nbsp;
                </div>
                {(myself?.rights?.role_superadmin || myself?.rights?.role_oubabox_provider) && (
                  <AddBox
                    toggleModal={toggleModal}
                    oubaboxId={data.id}
                  />
                )}
              </div>
            ) : (
              <div>
                <Boxes
                  datas={data.boxes}
                  myself={myself}
                  oubabox={data}
                  sizesList={sizesList}
                  toggleModal={toggleModal}
                  toggleConfirmDestroy={toggleConfirmDestroy}
                  handleUpdateBoxSize={handleUpdateBoxSize}
                  getOubaboxStatusColor={getOubaboxStatusColor}
                />
              </div>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Oubabox.propTypes = {
  data: PropTypes.object,
  sizesList: PropTypes.arrayOf(PropTypes.object),
  toggleModal: PropTypes.func,
  toggleDisplayLogs: PropTypes.func,
  toggleConfirmDestroy: PropTypes.func,
  handleUpdateBoxSize: PropTypes.func,
  getOubaboxStatusColor: PropTypes.func
}

AddBox.propTypes = {
  toggleModal: PropTypes.func,
  oubaboxId: PropTypes.number
};

export default memo(Oubabox);