import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmModal from "components/ConfirmModal";
import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";

import { AddBox } from "./Oubabox";
import { Brightness1 } from "@material-ui/icons";
import { makeOpen, makeEnable, makeFree } from "../../services/oubabox.services";

import {
  Backdrop,
  Select,
  makeStyles,
  MenuItem,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

moment.locale("fr");
const useStyles = makeStyles(styles);

const Boxes = ({
  datas,
  myself,
  oubabox,
  sizesList,
  toggleModal,
  toggleConfirmDestroy,
  handleUpdateBoxSize,
  getOubaboxStatusColor
}) => {
  const [modal, setModal] = useState();
  const classes           = useStyles();
  // FIXME: this method only works for version / release and not modification (cuz I only wanted to check 1.8 instead of 1.9)
  const getVersionNumber  = version => version?.match("[0-9]+(.[0-9]+)+$")?.[0] || "0";

  return (<>
    {modal?.open && (
      <Modal
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        className={classes.modal}
        onClose={setModal}
        open={modal.open ? true : false}
      >
        <div className={classes.paper}>
          <ConfirmModal
            closeModal={setModal}
            kind={modal.content}
            // Some kind of "black magic" for unexperienced one to unset modal
            makeAction={() => setModal(modal.action(modal.oubabox, modal.box))}
          />
        </div>
      </Modal>
    )}
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell align="center">
            Status
          </TableCell>
          <TableCell align="center">
            BID
          </TableCell>
          <TableCell align="center">
            Code
          </TableCell>
          <TableCell align="center">
            Propriétaire
          </TableCell>
          <TableCell align="center">
            Depuis
          </TableCell>
          <TableCell align="center">
            Note
          </TableCell>
          <TableCell align="center">
            Taille
          </TableCell>
          <TableCell align="center">
            Actions
          </TableCell>
          {(myself?.rights?.role_superadmin || myself?.rights?.role_oubabox_provider) && (
            <TableCell align="center">
              <AddBox
                toggleModal={toggleModal}
                oubaboxId={oubabox.id}
              />
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {datas?.map((box, key) => (
          <TableRow key={key}>
            <TableCell />
            <TableCell align="center" component="th" scope="row">
              <Brightness1 style={{
                color: getOubaboxStatusColor('container', box, oubabox),
                width: "12px"
              }} />
            </TableCell>
            <TableCell align="center" component="th" scope="row">
              {box?.bid}
            </TableCell>
            <TableCell align="center">
              {box?.delivery?.code}
            </TableCell>
            <TableCell align="center">
              {box?.delivery?.user?.email}
            </TableCell>
            <TableCell align="center">
              {box?.delivery && (
                <Tooltip
                  title={moment(box?.delivery?.createdAt).format("DD MMMM YYYY, HH[h]mm[min]")}
                  placement="top"
                >
                  <div>
                    {box?.delivery?.createdAt && moment(box.delivery.createdAt).fromNow(true)}
                  </div>
                </Tooltip>
              )}
            </TableCell>
            <TableCell align="center">
              {`${box.reducedMobility ? '♿ ' : ''}${box?.note}`}
            </TableCell>
            <TableCell align="center">
              <Select
                value={box?.size.id}
                onChange={({ target }) => handleUpdateBoxSize(box, target.value)}
                disabled={!(
                  myself?.rights?.role_superadmin
                  || myself.rights.role_oubabox_provider)}
              >
                {sizesList?.map((size, key) => (
                  <MenuItem value={size.id} key={key}>
                    {size.name}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
            <TableCell align="center">
              <div style={{
                display: "flex",
                justifyContent: "center"
              }}>
                <Button
                  size="sm"
                  color="info"
                  onClick={() => setModal({ open: true, ...{
                    action:  makeOpen,
                    content: `Ouvrir le casier ${box.bid} ?`,
                    oubabox: oubabox?.uuid,
                    box:     box?.uuid
                  }})
                }>
                  Ouvrir
                </Button>
                {getVersionNumber(oubabox.version) >= "1.9.2" && (
                  <Button
                    size="sm"
                    color={box?.autoDisabled ? "success" : "info"}
                    onClick={() => setModal({ open: true, ...{
                      action:  makeEnable,
                      content: `${box.disable || box.autoDisabled ? "Ré" : "Dés"}activer le casier ${box.bid} ?`,
                      oubabox: oubabox?.uuid,
                      box:     box?.uuid
                    }})
                  }>
                    {`${box.disable || box.autoDisabled ? "Ré" : "Dés"}activer`}
                  </Button>
                )}
                {getVersionNumber(oubabox.version) >= "1.9.2" && box?.delivery && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={() => setModal({ open: true, ...{
                      action:  makeFree,
                      content: `ATTENTION! ${box.delivery?.user?.callTitle || ""} ${box.delivery?.user?.firstName} ${box.delivery?.user?.lastName} n'auras plus son colis !`,
                      oubabox: oubabox?.uuid,
                      box:     box?.uuid
                    }})
                  }>
                    Libérer
                  </Button>
                )}
              </div>
            </TableCell>
            <TableCell align="center">
              <div>
                {(
                  myself?.rights?.role_superadmin ||
                  myself.rights.role_oubabox_provider) && (
                    <div style={{
                      display: "flex",
                      justifyContent: "center"
                    }}>
                      <Button
                        size="sm"
                        onClick={() => toggleModal(box, oubabox.id)}
                        color="warning"
                      >
                        Éditer
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => toggleConfirmDestroy(box)}
                        color="danger"
                      >
                        Supprimer
                      </Button>
                    </div>
                  )
                }
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </>);
};

Boxes.propTypes = {
  datas: PropTypes.array,
  myself: PropTypes.object,
  oubabox: PropTypes.object,
  sizesList: PropTypes.array,
  oubaboxId: PropTypes.object,
  toggleModal: PropTypes.func,
  toggleConfirmDestroy: PropTypes.func,
  handleUpdateBoxSize: PropTypes.func,
  getOubaboxStatusColor: PropTypes.func
};

export default memo(Boxes);