//A vouloir trop optimiser, on rend le code illisible... (AVTOORCI)
//Ce même fichier gère l'oubabox et ses casiers.

import { loadState } from "helpers/localStorage";
import { useEffect, useState } from "react";
import {
  attachOubaboxSectors,
  createDoor,
  createOubabox,
  detachOubaboxSectors,
  fetchEstateSectors,
  fetchOubaboxes,
  fetchOubaboxSectors,
  fetchSizes,
  removeDoor,
  removeOubabox,
  updateDoor,
  updateOubabox,
} from "../../services";

const GRAY_COLOR = "#777";
const YELLOW_COLOR = "#DB0";
const GREEN_COLOR = "#0F0";
const RED_COLOR = "#F00";

export default function OubaboxLogic() {
  const [refresh, setRefresh] = useState(false);
  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");
  const [page, setPage] = useState(1);
  const [oubaboxsList, setOubaboxsList] = useState();
  const [sizesList, setSizesList] = useState();
  const [estateSectorsList, setEstateSectorsList] = useState();
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [newItemInfos, setNewItemInfos] = useState();
  const [modalType, setModalType] = useState(false);
  const [itemToDestroy, setItemToDestroy] = useState();
  const [showLogs, setShowLogs] = useState();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();
  const [statusFilter, setStatusFilter] = useState([]);
  const [labelFilter, setLabelFilter] = useState([]);
  const [statesFilter, setStatesFilter] = useState([0, 1, 2, 3]);
  const [availableFilter, setAvailableFilter] = useState([0, 1]);
  const [incidentsFilter, setIncidentsFilter] = useState([0, 1]);
  const limitOubaboxs = 10000; //Would remove this limit on day... and so the pagination for Oubabox list.

  useEffect(() => {
    (async () => {
      const estateUUID = loadState("site")?.uuid;
      const estateSectors = await fetchEstateSectors(estateUUID);
      estateSectors && setEstateSectorsList(estateSectors);
    })();
  }, []);

  useEffect(() => {
    !showLogs &&
      (async () => {
        console.log("OubaboxLogic:fetchOubaboxes");
        const oubaboxs = await fetchOubaboxes(
          loadState("site")?.uuid
        );
        oubaboxs &&
          setOubaboxsList(
            oubaboxs.slice(
              limitOubaboxs * (page - 1),
              limitOubaboxs * (page - 1) + limitOubaboxs
            )
          );
        const sizes = await fetchSizes();
        sizes && setSizesList(sizes);
      })();
  }, [refresh, page]);

  // Refetch all oubabox each 2000ms
  useEffect(() => {
    const interval =
      showLogs?.uuid || setInterval(() => setRefresh(!refresh), 2000);
    return () => clearInterval(interval);
  });

  const refreshData = () => setRefresh(!refresh);
  const handlePagination = page => setPage(page);
  const toggleConfirmDestroy = item => setItemToDestroy(item);
  const toggleDisplayLogs = (uuid, data) => setShowLogs({ uuid, data });
  const handleFormChange = (key, value) =>
    setNewItemInfos({ ...newItemInfos, [key]: value });

  const handleRequestSort = (_, property) => {
    setOrder(orderBy === property && order === "asc" ? "desc" : "asc");
    setOrderBy(property);
  };

  const descendingComparator = (a, b, orderBy) =>
    b[orderBy] < a[orderBy] ? -1 : b[orderBy] > a[orderBy] ? 1 : 0;

  const getComparator = (order, orderBy) =>
    order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);

  const stableSort = (array, comparator) =>
    array
      .map((el, index) => [el, index])
      .sort((a, b) => {
        const order = comparator(a[0], b[0]);
        return order !== 0 ? order : a[1] - b[1];
      })
      .map(el => el[0]);
      
  const getOubaboxStatusColor = (type = '', data = {}, parent = {}) => {
    
    if (type === 'device'){
      if (data.isAvailable && data.state === 1){
        return GREEN_COLOR
      }else{
        if (!data.state){
          return GRAY_COLOR; 
        }else{
          if (data.state === 2){
            return YELLOW_COLOR;
          }else{
            return RED_COLOR;
          }
        }
      }
    }
    
    if (type === 'container'){
      if (data.disable || data.autoDisabled){
        return RED_COLOR
      } else {
        if (!data.connected || !parent.isAvailable || !parent.state) {
          return GRAY_COLOR;
        } else {
          if (data.closed && data.locked && !data.busy){
            return GREEN_COLOR;
          }else{
            return YELLOW_COLOR;
          }
        }
      }
    }
    
    return GRAY_COLOR;
  }

  const handleFilter = (type, item) => {
    type === "status" && setStatusFilter(item);
    type === "label" && setLabelFilter(item);
    type === "state" && setStatesFilter(item);
    type === "available" && setAvailableFilter(item);
  };

  const toggleModal = async (data = null, oubaboxId) => {
    setModalType(data ? true : false);

    let baseProps = {
      uuid: data?.uuid,
      note: data?.note || "",
      appearance: data?.appearance || "",
    };

    if (oubaboxId === undefined) {
      //If Edit Oubabox Device (AVTOORCI)
      let sectorUUIDs = [];
      if (data?.uuid) {
        const oubaboxSectors = await fetchOubaboxSectors(data.uuid);
        sectorUUIDs = oubaboxSectors.map(item => item.uuid);
      }
      const oubaboxProps = {
        label: data?.label || "",
        address: data?.address || "",
        city: data?.city || "",
        zipcode: data?.zipcode || "",
        countryA2: data?.countryA2 || "FR",
        rows: data?.rows || 0,
        cols: data?.cols || 0,
        model: data?.model || "1",
        access: data?.access || "ESTATE_USERS",
        latitude: data?.latitude ?? undefined,
        longitude: data?.longitude ?? undefined,
        isAvailable: data?.isAvailable ?? true,
        enaftLocalUsers: data?.enaftLocalUsers ?? false,
        enaftTransporter: data?.enaftTransporter ?? false,
        enaftPostal: data?.enaftPostal ?? false,
        enaftUserToUser: data?.enaftUserToUser ?? false,
        enaftExtProvider: data?.enaftExtProvider ?? false,
        sectorUUIDs: sectorUUIDs,
        estateId:
          data?.estateId ||
          JSON.parse(localStorage.getItem("site"))?.id ||
          undefined,
      };
      setNewItemInfos({ ...baseProps, ...oubaboxProps });
    } else {
      //Else Edit Oubabox Door/Container (AVTOORCI)
      const doorProps = {
        bid: data?.bid || "",
        connected: data?.connected ?? false,
        locked: data?.locked ?? true,
        closed: data?.closed ?? true,
        buzz: data?.buzz ?? false,
        busy: data?.busy ?? false,
        disable: data?.disable ?? false,
        autoDisabled: data?.autoDisabled ?? false,
        reducedMobility: data?.reducedMobility ?? false,
        row: data?.row || 0,
        col: data?.col || 0,
        subrow: data?.subrow || undefined,
        subcol: data?.subcol || undefined,
        code: data?.code || "",
        note: data?.note || "",
        sizeId: data?.sizeId || 1,
        oubaboxId: data?.oubaboxId || oubaboxId,
      };
      setNewItemInfos({ ...baseProps, ...doorProps });
    }
    setEditModalOpened(!editModalOpened);
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const makeAction = async (method, item, state) => {
    const response = await method();
    openNotification(
      //Blurp 🤢
      response
        ? `${
            item
              ? `L'Oubabox ${newItemInfos?.label || itemToDestroy.label}`
              : `Le casier ${newItemInfos?.bid || itemToDestroy.bid}`
          } à bien été ${((state && "créé") ?? "modifié") || "supprimé"}`
        : `Une erreur est survenu lors de la ${
            ((state && "création") ?? "modification") || "suppression"
          } ${
            item
              ? `de l'Oubabox ${newItemInfos?.label || itemToDestroy.label}`
              : `du casier ${newItemInfos?.bid || itemToDestroy.bid}`
          }`,
      response ? "success" : "danger"
    );
  };

  const deleteItem = async item => {
    item.label &&
      (await makeAction(() => removeOubabox(item.uuid), true, false));
    item.bid && (await makeAction(() => removeDoor(item.uuid), false, false));
    !item.label && !item.bid && openNotification("Une erreur est survenue");
    toggleConfirmDestroy(null);
    refreshData();
  };

  const updateOubaboxAndAssociates = async itemInfos => {
    let currentSectors = await fetchOubaboxSectors(itemInfos.uuid);
    currentSectors = currentSectors.map(item => item.uuid);
    let newSectorUUIDs = itemInfos.sectorUUIDs;
    let sameContent = currentSectors.filter(x => newSectorUUIDs.includes(x));
    let sectorsToBeAdded = newSectorUUIDs.filter(
      x => !currentSectors.includes(x)
    );
    let sectorsToBeRemoved = currentSectors.filter(
      x => !newSectorUUIDs.includes(x)
    );

    for (let i = 0; i < sectorsToBeAdded.length; i++) {
      const tba = sectorsToBeAdded[i];
      await attachOubaboxSectors(itemInfos.uuid, tba);
    }

    for (let i = 0; i < sectorsToBeRemoved.length; i++) {
      const tbr = sectorsToBeRemoved[i];
      await detachOubaboxSectors(itemInfos.uuid, tbr);
    }

    const response = await updateOubabox(itemInfos.uuid, itemInfos);
    return response;
  };

  const createOubaboxAndAssociates = async itemInfos => {
    const newOubabox = await createOubabox(itemInfos);
    const oubaboxUUID = newOubabox.oubabox.uuid;
    let newSectorUUIDs = itemInfos.sectorUUIDs;
    for (let i = 0; i < newSectorUUIDs.length; i++) {
      const suuid = newSectorUUIDs[i];
      await attachOubaboxSectors(oubaboxUUID, suuid);
    }
    return newOubabox;
  };

  const submitNewOubabox = async event => {
    event.preventDefault();
    if (newItemInfos.uuid) {
      await makeAction(() => updateOubaboxAndAssociates(newItemInfos), true);
    } else {
      const oubaboxUUID = oubaboxsList.find(
        o => o.id === newItemInfos.oubaboxId
      )?.uuid;
      await makeAction(
        () => createOubaboxAndAssociates(newItemInfos),
        true,
        true
      );
    }
    !newItemInfos.label &&
      !newItemInfos.bid &&
      openNotification(`Erreur`, "danger");
    setEditModalOpened(!editModalOpened);
    setNewItemInfos();
    refreshData();
  };

  const submitNewDoor = async event => {
    event.preventDefault();
    if (newItemInfos.uuid) {
      await makeAction(
        () => updateDoor(newItemInfos.uuid, newItemInfos),
        false
      );
    } else {
      const oubaboxUUID = oubaboxsList.find(
        o => o.id === newItemInfos.oubaboxId
      )?.uuid;
      await makeAction(
        () => createDoor(oubaboxUUID, newItemInfos),
        false,
        true
      );
    }
    !newItemInfos.label &&
      !newItemInfos.bid &&
      openNotification(`Erreur`, "danger");
    setEditModalOpened(!editModalOpened);
    setNewItemInfos();
    refreshData();
  };

  const handleUpdateBoxSize = async ({ uuid, bid }, sizeId) => {
    if (uuid) {
      await updateDoor(uuid, { sizeId });
      openNotification(`La boite ${bid} à bien été modifiée`, "success");
    } else {
      openNotification("Une erreur est survenue");
    }
    refreshData();
  };

  return {
    availableFilter,
    deleteItem,
    editModalOpened,
    estateSectorsList,
    getComparator,
    getOubaboxStatusColor,
    handleFilter,
    handleFormChange,
    handlePagination,
    handleRequestSort,
    handleUpdateBoxSize,
    incidentsFilter,
    itemToDestroy,
    labelFilter,
    limitOubaboxs,
    modalType,
    newItemInfos,
    notifColor,
    notifMessage,
    openNotif,
    order,
    orderBy,
    oubaboxsList,
    page,
    refreshData,
    setOpenNotif,
    showLogs,
    sizesList,
    stableSort,
    statesFilter,
    statusFilter,
    submitNewDoor,
    submitNewOubabox,
    toggleConfirmDestroy,
    toggleDisplayLogs,
    toggleModal,
  };
}
